<template>
  <div class="overflow-hidden">
    <div class="row mb-5 d-flex justify-center">
      <div class="col-12 col-md-5 col-lg-3">
        <v-img :src="bannerLogin" alt="login" />
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-12 col-md-5 col-lg-3 mx-auto">
        <div class="mx-auto password-reset-confirm">
          <div>
            <h2>Restablecer Contraseña</h2>
            <p>Ingresa tu nueva contraseña</p>
          </div>
          <validation-observer ref="form">
            <form
              novalidate
              autocomplete="off"
              @submit.prevent="passwordResetConfirm"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Nueva Contraseña"
                rules="required|verifyPassword"
              >
                <v-text-field
                  class="rounded-clg"
                  vid="password"
                  v-model="model.password"
                  :type="showPassword ? 'text' : 'password'"
                  outlined
                  dense
                  :error-messages="errors"
                  label="Nueva Contraseña"
                  required
                >
                  <v-icon slot="append" @click="showPassword = !showPassword">
                    {{ showPassword ? "mdi-eye-off" : "mdi-eye" }}
                  </v-icon>
                </v-text-field>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="Confirmar Contraseña"
                rules="required|confirmed:Nueva Contraseña"
              >
                <v-text-field
                  class="rounded-clg"
                  vid="password_confirmation"
                  v-model="model.password_confirmation"
                  :type="showPassword ? 'text' : 'password'"
                  outlined
                  dense
                  :error-messages="errors"
                  label="Confirmar Contraseña"
                  required
                >
                  <v-icon slot="append" @click="showPassword = !showPassword">
                    {{ showPassword ? "mdi-eye-off" : "mdi-eye" }}
                  </v-icon>
                </v-text-field>
              </validation-provider>
              <v-btn
                color="secondary"
                type="submit"
                class="rounded-lg text-capitalize black--text mb-9"
                block
              >
                Cambiar Contraseña
              </v-btn>
            </form>
          </validation-observer>
        </div>
      </div>
    </div>

    <div class="row d-flex justify-center">
      <div class="col-12 col-md-5 col-lg-3">
        <v-img :src="lowBannerLogin" alt="login" />
      </div>
    </div>
  </div>
</template>

<script>
import sessionMixin from "@/mixins/sessionMixin";
import crudServiceMixin from "@/mixins/crudServiceMixin";
import notificationMixin from "@/mixins/notificationMixin";
import loaderMixin from "@/mixins/loaderMixin";
import { PASSWORD_CHANGE_URL } from "@/constants/ServicesConstants";

export default {
  name: "PasswordResetConfirm",
  data: () => ({
    model: {
      username: "",
      password: "",
      password_confirmation: "",
    },
    showPassword: false,
  }),
  computed: {
    bannerLogin() {
      return require(`@/assets/images/banners/${process.env.VUE_APP_FAIR_RESOURCES_DIRECTORY}/superior_login.svg`);
    },
    lowBannerLogin() {
      return require(`@/assets/images/banners/${process.env.VUE_APP_FAIR_RESOURCES_DIRECTORY}/inferior_login.svg`);
    },
  },
  mixins: [sessionMixin, crudServiceMixin, notificationMixin, loaderMixin],
  methods: {
    passwordResetConfirm() {
      this.$refs.form.validate().then((valid) => {
        if (valid) {
          this.showLoader();
          this.model.username = this.getUserInfo.user.username;
          this.post(PASSWORD_CHANGE_URL, this.model)
            .then((res) => {
              this.showSuccess("Se ha cambiado la contraseña correctamente");
              this.changePassword().then(() => {
                setTimeout(() => {
                  this.$router.replace("/fairs/" + res.data.slug + "/visitors");
                  this.hideLoader();
                }, 3000);
              });
            })
            .catch((e) => {
              this.hideLoader();
              this.$refs.form.errors["Confirmar Contraseña"].push(
                e.response.data.error ||
                  e.response.data.password[0] ||
                  "Ha ocurrido un error."
              );
            });
        }
      });
    },
  },
};
</script>
<style scoped>
.password-reset-confirm {
  width: 80%;
}
</style>
